import { Component, Vue } from "vue-property-decorator";
declare module "vue/types/vue";

@Component
export default class FormValidator extends Vue {
  protected formData: any;

  get comuna_cliente() {
    return this.$nombreComuna;
  }

  formIsRequired(value: string) {
    if (value) {
      return true;
    }
    return this.$t("validation.required");
  }
  formIsRequiredArray(value: Array<any> | null) {
    if (value && value.length > 0) {
      return true;
    }
    return this.$t("validation.required");
  }
  formMinLength(value: string) {
    if (value.length >= 6) {
      return true;
    }
    return this.$t("validation.password");
  }
  formIsRequiredMotive(value: string) {
    if (value) {
      return true;
    }
    return this.$t("validation.required_motive");
  }

  formSelectIsRequired(value: any) {
    if (value.value || value.value == 0) {
      return true;
    }
    return "Debe seleccionar una opción";
  }
  formIsEmail(value: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(value) === true) {
      return true;
    }
    return this.$t("validation.email");
  }
  formIsPhone(value: number | string) {
    if (!value || value == "") return true;
    // eslint-disable-next-line
    if (Number(value) > 99999999 && Number(value) <= 999999999) {
      return true;
    }
    return this.$t("validation.phone");
  }
  formPasswordCofirm(value: string) {
    if (this.formData.password === value) {
      return true;
    }
    return this.$t("validation.password_same");
  }
  formPasswordNew(value: string) {
    if (this.formData.old_password != value) {
      return true;
    }
    return this.$t("validation.old_new_password");
  }
  formSelectOrType(value: string) {
    if (value) {
      return true;
    }
    return this.$t("validation.type_or_select");
  }
  public formIsVehicleId(value: string) {
    return true;
  }

  public isVehicleId(id: string) {
    return !this.isNumeric(id.substr(0, 3)) && this.isNumeric(id.substr(4, 5));
  }
  private isNumeric(value: string) {
    return /^\d+$/.test(value);
  }

  private isPassword(value: string) {
    if (value.length > 6 || value.length < 6) {
      return "Ingrese una contraseña válida.";
    }
  }

  public isRutForm(value: string) {
    var texto = value;
    if (texto.length > 12 || texto.length < 8) {
      return "Ingrese un rut válido";
    }
    var check_letters = texto.match(/([A-Za-z])/g);
    if (check_letters && check_letters.length >= 2) {
      return "El rut no puede tener 2 letras";
    }
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return this.$t("validation.invalid_rut");
  }

  public isRut(value: string) {
    if (this.formData.document_type != "1") {
      return true;
    }
    var texto = value;
    if (texto.length > 12 || texto.length < 9) {
      return "Ingrese un rut válido";
    }
    var check_letters = texto.match(/([A-Za-z])/g);
    if (check_letters && check_letters.length >= 2) {
      return "El rut no puede tener 2 letras";
    }
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return this.$t("validation.invalid_rut");
  }

  public isOnlyRut(value: string) {
    var texto = value;
    var check_letters = texto.match(/([A-Za-z])/g);
    if (check_letters && check_letters.length >= 2) {
      return "El rut no puede tener 2 letras";
    }
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return this.$t("validation.invalid_rut");
  }
  public revisarDigito(dvr: any) {
    var dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: any) {
    var largo = crut.length;
    if (largo < 2) {
      return false;
    }
    if (largo > 2) var rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    var dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    var dvr = "0";
    var suma = 0;
    var mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + rut.charAt(i) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    var res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      var dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }

  protected formatRut() {
    if (!this.formData.document_id || this.formData.document_type != "1") {
      return true;
    }
    if (
      this.formData.document_id.replace("-", "").replace(/[.-]/g, "").length <=
      7
    ) {
      this.formData.document_id = this.formData.document_id
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/\s/g, "")
        .replace(/^(\d{3})(\d{3})(\w{1})$/, "$1.$2-$3");
    } else {
      this.formData.document_id = this.formData.document_id
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/\s/g, "")
        .replace(/^(\d{0,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
    }
  }

  protected formatOnlyRut() {
    if (
      this.formData.document_id.replace("-", "").replace(/[.-]/g, "").length <=
      7
    ) {
      this.formData.document_id = this.formData.document_id
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/\s/g, "")
        .replace(/^(\d{3})(\d{3})(\w{1})$/, "$1.$2-$3");
    } else {
      this.formData.document_id = this.formData.document_id
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/\s/g, "")
        .replace(/^(\d{0,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
    }
  }

  protected apiStreets(val: string) {
    return this.$axios
      .get("calles", {
        params: {
          filter: {
            where: {
              nombre: {
                like: "%" + val + "%"
              },
              estado: 1
            },
            fields: ["id", "nombre", "estado"]
          }
        }
      })
      .then(response => {
        let streets = response.data;
        return streets;
      })
      .catch(error => {
        return error;
      });
  }

  public checkDate(value: string) {
    const dia = value.split("")[0] + value.split("")[1];
    const mes = value.split("")[3] + value.split("")[4];
    const ano =
      value.split("")[6] +
      value.split("")[7] +
      value.split("")[8] +
      value.split("")[9];
    let dia_final = 28;
    if (
      Number(mes) == 1 ||
      Number(mes) == 3 ||
      Number(mes) == 5 ||
      Number(mes) == 7 ||
      Number(mes) == 8 ||
      Number(mes) == 10 ||
      Number(mes) == 12
    ) {
      dia_final = 31;
    } else if (
      Number(mes) == 4 ||
      Number(mes) == 6 ||
      Number(mes) == 9 ||
      Number(mes) == 11
    ) {
      dia_final = 30;
    } else {
      if (
        Number(ano) == 2020 ||
        Number(ano) == 2024 ||
        Number(ano) == 2028 ||
        Number(ano) == 2032 ||
        Number(ano) == 2036 ||
        Number(ano) == 2040 ||
        Number(ano) == 2044 ||
        Number(ano) == 2048 ||
        Number(ano) == 2052 ||
        Number(ano) == 2056 ||
        Number(ano) == 2060
      ) {
        dia_final = 29;
      }
    }
    if (Number(dia) < 1 || Number(dia) > dia_final) {
      return false;
    }
    if (Number(mes) < 1 || Number(mes) > 12) {
      return false;
    }
    if (Number(ano) < 1800 || Number(ano) > 2060) {
      return false;
    }
    return true;
  }

  public checkHour(value: string) {
    const hora = value.split("")[0] + value.split("")[1];
    const minuto = value.split("")[3] + value.split("")[4];

    if (Number(hora) < 0 || Number(hora) > 23) {
      return false;
    }
    if (Number(minuto) < 0 || Number(minuto) > 59) {
      return false;
    }
    return true;
  }

  private dummy() {
    return true;
  }
}
